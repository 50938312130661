<!--footer section -->
<footer [class]="class">
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="footer-logo" style="    display: flex; justify-content: start;" *ngIf="showMobile">
					<img src="../../../../assets/img/eezi-logo.png" alt="logo" style="height: 100px;margin-top: -50px;">
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo" *ngIf="!showMobile">
							<img src="../../../../assets/img/eezi-logo.png" alt="logo" style="height: 100px;margin-top: -50px;">
						</div>
						<p>Delivering exceptional healthcare equipment, ensuring reliability, accuracy, safety, and innovation for superior patient care and customer satisfaction</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/p/Neomedic-Pty-Ltd-100057659971100" target="auto_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li> -->
                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li> -->
                                <li>
                                    <a href="https://www.instagram.com/neomed01/?hl=en" target="auto_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="
									https://wa.me/0722696891?text=Hi%20I%20was%20browsing%20your%20site%20and%20would%20like%20to%20enquire" target="auto_blank"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Our Company</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a routerLink="/home/neomedic"  fragment="about-us">About Us</a></li>
                                <li><a (click)="AgeModal.openModal('tnc')">Terms and Conditions</a></li>
								<li><a (click)="AgeModal.openModal('brochure')" >Brochure</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Your Account</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Orders</a></li>
                                <li><a href="javascript:void(0)">Personal Info</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>store information</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>
									90 Corobrick Road,
									Bella Vista Estate #5 ,
									Riverhorse Valley East
									Durban, South Africa
									40177</li>
						        <li><i class="fa fa-phone"></i>(+27) 31 533 9680</li>
						        <li><i class="fa fa-envelope-o"></i><a style="text-transform:lowercase!important;">info@neomedic.net</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- <div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} powered by Digital District</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div> -->
</footer>
<app-age-verification #ageVerificationComponent *ngIf="cartModal"></app-age-verification>
<!--footer section end -->