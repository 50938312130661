// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'neomedic-test',
    appId: '1:542156369725:web:2b49344c7f062595e59f54',
    storageBucket: 'neomedic-test.appspot.com',
    apiKey: 'AIzaSyBjQ60as0qFpHzscqteZfxOAlF6foclTeE',
    authDomain: 'neomedic-test.firebaseapp.com',
    messagingSenderId: '542156369725',
    measurementId: 'G-X1QP4TS9D0',
  },
  production: false,
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
