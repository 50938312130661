<ul class="menu-list">
    <div class="container row">
        <div class="col-6">
            <ul class="vertical-list">
                <li>
                    <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Needles',type:'medical'}" class="title-link">
                        <div class="title">Needles</div>
                    </a>
                </li>
                <li>
                    <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Syringes',type:'medical'}">
                    <div class="title" >
                         Syringes    
                    </div>
                </a>
                </li>
                <li>
                    <a routerLink="/shop/collection/right/sidebar"  [queryParams]="{ category: 'Cotton Wool',type:'medical'}">
                        <div class="title" >    
                            Cotton Wool
                    </div>
                </a>
                </li>
                <li>
                    <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Gloves',type:'medical'}" >
                        <div class="title">
                             Gloves
                        </div>
                    </a>
                </li>
            </ul>
        </div>

        <div class="col-6">
            <ul class="vertical-list">
                <li>
                    <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Intravenous',type:'medical'}">
                    <div class="title" >
                         Intravenous    
                    </div>
                </a>
                </li>
                <li>
                    <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Diagnostics',type:'medical'}">
                        <div class="title"  >
                            Diagnostics
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Patient Monitoring',type:'medical'}">
                    <div class="title">
                         Patient Monitoring    
                    </div>
                </a>
                </li>
                <li>
                    <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Urinary',type:'medical'}"> 
                    <div class="title">    
                            Urinary
                    </div>
                </a>
                </li>
                <!-- <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar">
                        <a > Consumables
                        </a>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
</ul>
