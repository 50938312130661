<div id="header-sticky" class="header-top" *ngIf="!showMobile">
    <div class="row sticky" style="height: 135px;    position: relative;
    width: 100%;
    z-index: 999999;
    background: #013368;
    width: 100%;"
    [ngClass]="{'scrolled': stick}">
        <div class="hidden-md-down col-md-2 offset-1" id="_desktop_logo">
            <a routerLink="/home/neomedic">
                <img class="logo img-responsive" src="https://neomedic.net/assets/logo.png" alt="Medici | Home2">
            </a>
        </div>
        <div class="position-static hidden-md-down col-6" id="header-menu">
            <div class="container">
                <div class="row" style="margin-top: 15px;">
                    <div id="_desktop_fieldmegamenu" class="fieldmegamenu">
                        <ul>

                            <li class="root root-1 menu-home horizontal">
                                <div class="root-item no-description pull-left">

                                    <a routerLink="/home/neomedic">
                                        <div class="title title_font">
                                            <span class="title-text">HOME
                                            </span>
                                            
                                        </div>
                                    </a>
                                </div>
                            <li class="root root-3 menu_1 horizontal">
                              <div class="root-item no-description pull-left">
                                  <a routerLink="/shop/collection/right/sidebar">
                                      <div class="title title_font"><span class="title-text">Medical Supplies</span><span class="icon-has-sub fa fa-angle-down hidden-sm-down" aria-hidden="true"></span>
                                      </div>
                                  </a>
                                  <div class="dropdown">
                                   <app-menu-items></app-menu-items>
                                  </div>
                              </div>

                              <!-- <div data-toggle="collapse" data-parent="#_mobile_fieldmegamenu" data-target="#submegamenu-3" class="collapsed-menu collapsed hidden-lg-up pull-right" aria-expanded="false" role="button">
                                  <i class="fa fa-plus add" aria-hidden="true"></i>
                                  <i class="fa fa-minus remove" aria-hidden="true"></i>
                              </div> -->
                              <ul id="submegamenu-3" class="menu-items col-md-6 col-xs-12 collapse">
                                  <li class="menu-item menu-item-4 depth-1 customcontent menucol-1-3">
                                      <div class="normalized">
                                          <div class="img1"><a href="#"><img class="img-responsive" src="https://apps.digitaldistrict.co.za/medici/modules/fieldmegamenu/images/img1_menu.jpg" alt=""></a></div>
                                      </div>
                                  </li>
                                  <li class="menu-item menu-item-5 depth-1 customcontent menucol-1-3">
                                      <div class="normalized">
                                          <div class="img2"><a href="#"><img class="img-responsive" src="https://apps.digitaldistrict.co.za/medici/modules/fieldmegamenu/images/img2_menu.jpg" alt=""></a></div>
                                      </div>
                                  </li>
                                  <li class="menu-item menu-item-6 depth-1 divider"></li>
                                  <li class="menu-item menu-item-7 depth-1 category menucol-1-3">
                                      <div data-toggle="collapse" data-parent="#submegamenu-3" data-target="#submenu-8" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                          <i class="fa fa-plus add" aria-hidden="true"></i>
                                          <i class="fa fa-minus remove" aria-hidden="true"></i>
                                      </div>
                                      <div class="has_icon hidden-sm-down pull-right">
                                          <i class="fa open" aria-hidden="true"></i>
                                      </div>
                                      <ul id="submenu-8" class="submenu submenu-depth-2 collapse">
                                          <li class="menu-item menu-item-8 depth-2 category   ">
                                              <div class="title">
                                                  <a routerLink="/shop/collection/right/sidebar"> Needles
                                                  </a>
                                              </div>
                                          </li>

                                          <li class="menu-item menu-item-9 depth-2 category">
                                              <div class="title">
                                                  <a routerLink="/shop/collection/right/sidebar"> Syringes
                                                  </a>
                                              </div>
                                          </li>

                                          <li class="menu-item menu-item-10 depth-2 category ">
                                              <div class="title">
                                                  <a routerLink="/shop/collection/right/sidebar">Cotton Wool
                                                  </a>
                                              </div>
                                          </li>

                                          <li class="menu-item menu-item-11 depth-2 category   ">


                                              <div class="title">
                                                  <a routerLink="/shop/collection/right/sidebar"> Air Management
                                                  </a>
                                              </div>
                                          </li>

                                          <li class="menu-item menu-item-12 depth-2 category   ">
                                              <div class="title">
                                                  <a routerLink="/shop/collection/right/sidebar"> Gloves
                                                  </a>
                                              </div>
                                          </li>
                                      </ul>
                                  </li>

                                  <li class="menu-item menu-item-13 depth-1 category menucol-1-3  ">


                                      <!-- <div class="title title_font">
                                          <a href="14-pharmacy.testing"> pharmacy
                                          </a>
                                      </div> -->







                                      <div data-toggle="collapse" data-parent="#submegamenu-3" data-target="#submenu-14" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                          <i class="fa fa-plus add" aria-hidden="true"></i>
                                          <i class="fa fa-minus remove" aria-hidden="true"></i>
                                      </div>
                                      <div class="has_icon hidden-sm-down pull-right">
                                          <i class="fa open" aria-hidden="true"></i>
                                      </div>
                                      <ul id="submenu-14" class="submenu submenu-depth-2 collapse">

                                          <li class="menu-item menu-item-14 depth-2 category   ">


                                              <div class="title">
                                                  <a href="20-equipment.testing"> Intravenous
                                                  </a>
                                              </div>




                                          </li>




                                          <li class="menu-item menu-item-15 depth-2 category   ">


                                              <div class="title">
                                                  <a href="15-home-brewing.testing"> Diagnostics
                                                  </a>
                                              </div>




                                          </li>




                                          <li class="menu-item menu-item-16 depth-2 category   ">


                                              <div class="title">
                                                  <a href="17-knife-accessories.testing"> Patient Monitoring
                                                  </a>
                                              </div>




                                          </li>




                                          <li class="menu-item menu-item-17 depth-2 category   ">


                                              <div class="title">
                                                  <a href="19-coffee.testing"> Urinary
                                                  </a>
                                              </div>




                                          </li>




                                          <li class="menu-item menu-item-18 depth-2 category   ">


                                              <div class="title">
                                                  <a href="18-utensils-gadgets.testing"> Consumables
                                                  </a>
                                              </div>




                                          </li>



                                      </ul>
                                  </li>

                                  <li class="menu-item menu-item-19 depth-1 customcontent menucol-1-3  ">


                                      <div class="normalized">
                                          <div class="img3"><a href="#"><img class="img-responsive" src="https://apps.digitaldistrict.co.za/medici/modules/fieldmegamenu/images/img3_menu.jpg" alt=""></a></div>
                                      </div>





                                  </li>

                              </ul>

                          </li>


                            <li class="root root-4 menu_2 horizontal">
                                <div class="root-item no-description pull-left">

                                    <a routerLink="/shop/collection/right/sidebar">
                                        <div class="title title_font"><span class="title-text">Workwear</span><span class="icon-has-sub fa fa-angle-down hidden-sm-down" aria-hidden="true"></span>
                                        </div>
                                    </a>
                                    <div class="dropdown">
                                        <app-range-items></app-range-items>
                                       </div>
                                </div>
                                <div data-toggle="collapse" data-parent="#_mobile_fieldmegamenu" data-target="#submegamenu-4" class="collapsed-menu collapsed hidden-lg-up pull-right" aria-expanded="false" role="button">
                                    <i class="fa fa-plus add" aria-hidden="true"></i>
                                    <i class="fa fa-minus remove" aria-hidden="true"></i>
                                </div>
                                <ul id="submegamenu-4" class="menu-items col-md-4 col-xs-12 collapse">



                                    <li class="menu-item menu-item-20 depth-1 category menucol-1-2  ">


                                        <!-- <div class="title title_font">
                                            <a href="13-medicine.testing"> Medicine
                                            </a>
                                        </div> -->







                                        <div data-toggle="collapse" data-parent="#submegamenu-4" data-target="#submenu-21" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                            <i class="fa fa-plus add" aria-hidden="true"></i>
                                            <i class="fa fa-minus remove" aria-hidden="true"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open" aria-hidden="true"></i>
                                        </div>
                                        <ul id="submenu-21" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-21 depth-2 category   ">


                                                <div class="title">
                                                    <a href="15-home-brewing.testing"> Plasterman
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-22 depth-2 category   ">


                                                <div class="title">
                                                    <a href="16-table-linens.testing"> Nebu Mist
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-23 depth-2 category   ">


                                                <div class="title">
                                                    <a href="17-knife-accessories.testing"> MiDrop
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-24 depth-2 category   ">


                                                <div class="title">
                                                    <a href="18-utensils-gadgets.testing"> Aqua Sanz
                                                    </a>
                                                </div>




                                            </li>




                                            <!-- <li class="menu-item menu-item-25 depth-2 category   ">


                                                <div class="title">
                                                    <a href="19-coffee.testing"> Preg N Care
                                                    </a>
                                                </div>




                                            </li> -->



                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-26 depth-1 category menucol-1-2  ">


                                        <!-- <div class="title title_font">
                                            <a href="14-pharmacy.testing"> pharmacy
                                            </a>
                                        </div> -->







                                        <div data-toggle="collapse" data-parent="#submegamenu-4" data-target="#submenu-27" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                            <i class="fa fa-plus add" aria-hidden="true"></i>
                                            <i class="fa fa-minus remove" aria-hidden="true"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open" aria-hidden="true"></i>
                                        </div>
                                        <ul id="submenu-27" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-27 depth-2 category   ">


                                                <div class="title">
                                                    <a href="20-equipment.testing"> Preg N Care
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-28 depth-2 category   ">


                                                <div class="title">
                                                    <a href="15-home-brewing.testing"> Healthease
                                                    </a>
                                                </div>




                                            </li>




                                            <!-- <li class="menu-item menu-item-29 depth-2 category   ">


                                                <div class="title">
                                                    <a href="17-knife-accessories.testing"> Knife
                                                        Accessories
                                                    </a>
                                                </div>




                                            </li> -->




                                            <!-- <li class="menu-item menu-item-30 depth-2 category   ">


                                                <div class="title">
                                                    <a href="19-coffee.testing"> Coffee
                                                    </a>
                                                </div>




                                            </li> -->




                                            <!-- <li class="menu-item menu-item-31 depth-2 category   ">


                                                <div class="title">
                                                    <a href="18-utensils-gadgets.testing"> Utensils &
                                                        Gadgets
                                                    </a>
                                                </div>






                                            </li> -->
                                        </ul>
                                    </li>

                                </ul>

                            </li>


                            <li class="root root-5 ">
                                <div class="root-item no-description pull-left">

                                    <a routerLink="/pages/aboutus"  fragment="about-us">
                                        <div class="title title_font"><span class="title-text">About Us</span></div>
                                    </a>
                                </div>

                            </li>


                            <!-- <li class="root root-6 menu_3 horizontal">
                                <div class="root-item no-description pull-left">

                                    <a href="/medici/en/12-gallery">
                                        <div class="title title_font"><span
                                                class="title-text">GALLERY</span><span
                                                class="icon-has-sub fa fa-angle-down hidden-sm-down"></span>
                                        </div>
                                    </a>
                                </div>
                                <div data-toggle="collapse" data-parent="#_mobile_fieldmegamenu"
                                    data-target="#submegamenu-6"
                                    class="collapsed-menu collapsed hidden-lg-up pull-right"
                                    aria-expanded="false" role="button">
                                    <i class="fa fa-plus add"></i>
                                    <i class="fa fa-minus remove"></i>
                                </div>
                                <ul id="submegamenu-6" class="menu-items col-md-6 col-xs-12 collapse">



                                    <li class="menu-item menu-item-32 depth-1 category menucol-1-3  ">


                                        <div class="title title_font">
                                            <a href="21-gift-for-men.testing"> Gift for men
                                            </a>
                                        </div>







                                        <div data-toggle="collapse" data-parent="#submegamenu-6"
                                            data-target="#submenu-33"
                                            class="collapsed-menu collapsed hidden-sm-up pull-right"
                                            aria-expanded="false" role="button">
                                            <i class="fa fa-plus add"></i>
                                            <i class="fa fa-minus remove"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open"></i>
                                        </div>
                                        <ul id="submenu-33" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-33 depth-2 category   ">


                                                <div class="title">
                                                    <a href="25-objects.testing"> Objects
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-34 depth-2 category   ">


                                                <div class="title">
                                                    <a href="26-tabletop.testing"> Tabletop
                                                    </a>
                                                </div>




                                            </li>



                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-35 depth-1 category menucol-1-3  ">


                                        <div class="title title_font">
                                            <a href="22-vitamins.testing"> vitamins
                                            </a>
                                        </div>







                                        <div data-toggle="collapse" data-parent="#submegamenu-6"
                                            data-target="#submenu-36"
                                            class="collapsed-menu collapsed hidden-sm-up pull-right"
                                            aria-expanded="false" role="button">
                                            <i class="fa fa-plus add"></i>
                                            <i class="fa fa-minus remove"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open"></i>
                                        </div>
                                        <ul id="submenu-36" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-36 depth-2 category   ">


                                                <div class="title">
                                                    <a href="29-fishing.testing"> Fishing
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-37 depth-2 category   ">


                                                <div class="title">
                                                    <a href="30-fpv-system-parts.testing"> FPV System &
                                                        Parts
                                                    </a>
                                                </div>




                                            </li>



                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-38 depth-1 category menucol-1-3  ">


                                        <div class="title title_font">
                                            <a href="21-gift-for-men.testing"> Gift for men
                                            </a>
                                        </div>







                                        <div data-toggle="collapse" data-parent="#submegamenu-6"
                                            data-target="#submenu-39"
                                            class="collapsed-menu collapsed hidden-sm-up pull-right"
                                            aria-expanded="false" role="button">
                                            <i class="fa fa-plus add"></i>
                                            <i class="fa fa-minus remove"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open"></i>
                                        </div>
                                        <ul id="submenu-39" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-39 depth-2 category   ">


                                                <div class="title">
                                                    <a href="27-tables.testing"> Tables
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-40 depth-2 category   ">


                                                <div class="title">
                                                    <a href="28-chairs.testing"> Chairs
                                                    </a>
                                                </div>




                                            </li>



                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-41 depth-1 divider   ">



                                    </li>




                                    <li class="menu-item menu-item-42 depth-1 category menucol-1-3  ">


                                        <div class="title title_font">
                                            <a href="23-girls.testing"> Girls
                                            </a>
                                        </div>







                                        <div data-toggle="collapse" data-parent="#submegamenu-6"
                                            data-target="#submenu-43"
                                            class="collapsed-menu collapsed hidden-sm-up pull-right"
                                            aria-expanded="false" role="button">
                                            <i class="fa fa-plus add"></i>
                                            <i class="fa fa-minus remove"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open"></i>
                                        </div>
                                        <ul id="submenu-43" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-43 depth-2 category   ">


                                                <div class="title">
                                                    <a href="34-golf-supplies.testing"> Golf Supplies
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-44 depth-2 category   ">


                                                <div class="title">
                                                    <a href="35-gift-for-women.testing"> Gift for Women
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-45 depth-2 category   ">


                                                <div class="title">
                                                    <a href="36-salon-spa.testing"> Salon & Spa
                                                    </a>
                                                </div>




                                            </li>



                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-46 depth-1 category menucol-1-3  ">


                                        <div class="title title_font">
                                            <a href="24-earings.testing"> Earings
                                            </a>
                                        </div>







                                        <div data-toggle="collapse" data-parent="#submegamenu-6"
                                            data-target="#submenu-47"
                                            class="collapsed-menu collapsed hidden-sm-up pull-right"
                                            aria-expanded="false" role="button">
                                            <i class="fa fa-plus add"></i>
                                            <i class="fa fa-minus remove"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open"></i>
                                        </div>
                                        <ul id="submenu-47" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-47 depth-2 category   ">


                                                <div class="title">
                                                    <a href="21-gift-for-men.testing"> Gift for men
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-48 depth-2 category   ">


                                                <div class="title">
                                                    <a href="35-gift-for-women.testing"> Gift for Women
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-49 depth-2 category   ">


                                                <div class="title">
                                                    <a href="29-fishing.testing"> Fishing
                                                    </a>
                                                </div>




                                            </li>



                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-50 depth-1 category menucol-1-3  ">


                                        <div class="title title_font">
                                            <a href="22-vitamins.testing"> vitamins
                                            </a>
                                        </div>







                                        <div data-toggle="collapse" data-parent="#submegamenu-6"
                                            data-target="#submenu-51"
                                            class="collapsed-menu collapsed hidden-sm-up pull-right"
                                            aria-expanded="false" role="button">
                                            <i class="fa fa-plus add"></i>
                                            <i class="fa fa-minus remove"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open"></i>
                                        </div>
                                        <ul id="submenu-51" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-51 depth-2 category   ">


                                                <div class="title">
                                                    <a href="31-coffee-tables.testing"> Coffee Tables
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-52 depth-2 category   ">


                                                <div class="title">
                                                    <a href="32-sofas.testing"> Sofas
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-53 depth-2 category   ">


                                                <div class="title">
                                                    <a href="33-sofas-tables.testing"> Sofas tables
                                                    </a>
                                                </div>




                                            </li>



                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-55 depth-1 divider   ">



                                    </li>




                                    <li
                                        class="menu-item menu-item-54 depth-1 customcontent menucol-1-1  ">


                                        <div class="normalized">
                                            <div class="img4"><a href="#"><img class="img-responsive"
                                                        src="/medici/modules/fieldmegamenu/images/img4_menu.jpg"
                                                        alt></a></div>
                                        </div>





                                    </li>

                                </ul>

                            </li> -->


                            <li class="root root-7 ">
                                <div class="root-item no-description pull-left">

                                    <a routerLink="/pages/contact">
                                        <div class="title title_font"><span class="title-text">CONTACT</span></div>
                                    </a>
                                </div>

                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>
        <div class="col-3" style="display: flex; justify-content: end;">
            <div>
                <app-settings></app-settings>
              </div>
        </div>
        <!-- <div class="col-2 offset-1 menu-right">
          <div>
              <app-settings></app-settings>
            </div>
      </div> -->
    </div>
</div>

<div style="display: flex; align-items: center" *ngIf="showMobile">
    <div class="hamburger-menu col-4">
        <div class="hamburger-icon" (click)="toggleMenu()">
        <span></span>
        <span></span>
        <span></span>
        </div>
        <ul class="menu-items" [ngClass]="{ 'menu-open': isMenuOpen }">
            <li class="nav-item">
                <a (click)="navigateTo('/home/neomedic')" class="nav-link"> HOME </a>
            </li>
            <li class="nav-item">
                <a (click)="navigateTo('/shop/collection/right/sidebar')" class="nav-link"> MEDICAL SUPPLIES </a>
            </li>
            <li class="nav-item">
                <a (click)="navigateTo('/pages/aboutus')" class="nav-link"> ABOUT US </a>
            </li>
            <li class="nav-item">
                <a (click)="navigateTo('/pages/contact')" class="nav-link"> CONTACT </a>
            </li>
        </ul>
    </div>
    <div class="col-4">
        <img src="../../../../assets/images/neomedic/healthease_blue.png">
    </div>
    <div class="col-4" style="display: flex; justify-content: end;">
        <app-settings></app-settings>
    </div>
</div>