import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  @Input() type: string;

  constructor(public productService: ProductService, private firebaseService: FirebaseService) { 
    this.firebaseService.routeSubject.subscribe((data) => {
      this.type = data;
      this.productService.getProducts.subscribe(product => this.products = product);
    })
    
  }

  ngOnInit(): void {
  }

  get filterbyCategory() {
    const category = [...new Set(this.products.map(product => product.type))]
    return category
  }

}
