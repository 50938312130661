import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeWord'
})
export class RemoveWordPipe implements PipeTransform {
  transform(value: string, wordToRemove: string): string {
    // Check if the input value is a string and the word to remove is provided
    if (typeof value === 'string' && wordToRemove) {
      // Use the JavaScript replace method with a regular expression to remove all instances of the word
      return value.replace(new RegExp(wordToRemove, 'g'), '');
    }
    // Return the original value if it's not a string or the word to remove is not provided
    return value;
  }
}
