<ul class="menu-list">
    <div class="container row">
        <div class="col-6">
            <ul class="vertical-list">
                <li *ngFor="let range of rangeItems">
                    <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: range.name,type:'workwear'}" style="text-decoration: none;color: black;">
                    <div class="title"  >
                            {{range.name}}    
                    </div>
                </a>
                </li>
                <!-- <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ brand: 'Aqua Sanz'}">
                        <a> Aqua Sanz 
                        </a>
                    </div>
                </li> -->
                <!-- <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar"  [queryParams]="{ brand: 'MiDrop'}">
                        <a>MiDrop
                        </a>
                    </div>
                </li>
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{brand: 'Preg `n Care'}">
                        <a > Preg `n Care
                        </a>
                    </div>
                </li>
            </ul>
        </div>

        <div class="col-6">
            <ul class="vertical-list">
                <li>
                    <div class="title" routerLink="/shop/collection/right/sidebar" [queryParams]="{ brand: 'Nebu Mist'}">
                        <a> Nebu Mist
                        </a>
                    </div>
                </li> -->
                <!-- <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Diagnostics'}">
                        <a> Diagnostics
                        </a>
                    </div>
                </li>
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Patient Monitoring'}">
                        <a> Patient Monitoring
                        </a>
                    </div>
                </li>
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Gloves'}">
                        <a > Urinary
                        </a>
                    </div>
                </li> -->
                <!-- <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar">
                        <a > Consumables
                        </a>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
</ul>
