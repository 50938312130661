import { Component } from '@angular/core';

@Component({
  selector: 'app-range-items',
  templateUrl: './range-items.component.html',
  styleUrls: ['./range-items.component.scss']
})
export class RangeItemsComponent {

  rangeItems = [
    {
      name: 'Body Protection',
    },
    {
      name: 'Corporate Range',
    },
    {
      name: 'Eyewear',
    },
    {
      name: 'Footwear',
    },
    {
      name: 'Gloves',
    },
    {
      name: 'Head & Face',
    },
    {
      name: 'Hearing',
    },
    {
      name: 'Respiratory Range',
    },
    {
      name: 'Storm Range',
    },
    {
      name: 'Support',
    }
  ]

}
