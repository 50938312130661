import { TreeError } from '@angular/compiler';
import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({ name: 'appFilter' })
// export class FilterPipe implements PipeTransform {
//   /**
//    * Pipe filters the list of elements based on the search text provided
//    *
//    * @param items list of elements to search in
//    * @param searchText search string
//    * @returns list of elements filtered by search text or []
//    */
//   transform(items: any[], searchText: string): any[] {
//     if (!items) {
//       return [];
//     }
//     if (!searchText) {
//       return items;
//     }
//     searchText = searchText.toLocaleLowerCase();


//     return items.filter(it => {            
//       return it.toLocaleLowerCase().includes(searchText);
//     });
//   }
// }

@Pipe({
    name: "appFilter"
  })
  export class FilterPipe {
    transform(obj: any, searchText) {
      console.log(searchText);
        var term = searchText;
      if (obj != null && term) {
        return obj.filter( el => {
  
          var test = JSON.parse(JSON.stringify(el));
          delete test['url'];
          delete test['_id'];
            
          var testString = JSON.stringify(test);

  
          Object.keys(test).forEach(k => {
            testString = testString.replace(k, '');
          });
          let terms = term.replace(/[\s]+/gm, " ").replace(/^[\s]|[\s]$/gm, "").split(' ');
          let containCount = 0;

  
          terms.forEach(t => {
            if(testString.toLowerCase().indexOf(t.toLowerCase()) > -1)
            {
              ++containCount;
            }
          });
  
          return (containCount == terms.length);
        });
  
      } else {
        return obj;
      }
    }
  }