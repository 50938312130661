import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { PdfViewerComponent } from '../../modal/pdf-viewer/pdf-viewer.component';
import { AgeVerificationComponent } from "../../components/modal/age-verification/age-verification.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsletterComponent } from '../../components/modal/newsletter/newsletter.component';
import { FirebaseService } from '../../services/firebase.service'

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {
  showMobile: boolean = true;
  screenWidth: any;

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  cartModal: boolean = true;

  public today: number = Date.now();

  @ViewChild("ageVerificationComponent") AgeModal: AgeVerificationComponent;

  email: string;

  constructor(private modalService: NgbModal, private _firebaseService: FirebaseService) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 769){
      this.showMobile = true;
    }
    else {
      this.showMobile = false;
    }  
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.screenWidth = (<Window>event.target).innerWidth;

    if (this.screenWidth < 769){
      this.showMobile = true;
    }
    else {
      this.showMobile = false;
    }  
  }

  openPdfViewerModal() {
    const modalRef = this.modalService.open(NewsletterComponent);
    // Pass the PDF URL to the modal component
    modalRef.componentInstance.pdfUrl = 'assets/my-document.pdf';
  }

  saveEmail(){
    this._firebaseService.saveNewletterEmails(this.email);
    this.email = '';
    scrollTo(0,0);
  }

  isEmail(input) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(input);
  }
}
