<ng-template class="theme-modal">
    <div class="modal-header">
      <h4 class="modal-title">PDF Viewer</h4>
      <button type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ngx-extended-pdf-viewer
      [src]="'http://apps.digitaldistrict.co.za/neo-proto-5/assets/documents/NeomedicBrochure.pdf'"
      [height]="'500px'"
      [useBrowserLocale]="true"
      ></ngx-extended-pdf-viewer>
    </div>
</ng-template>