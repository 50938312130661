<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <!-- <img src="assets/images/icon/search.png" class="img-fluid" alt=""> -->
        <!-- <i class="fa fa-search" style="color: black" *ngIf="showMobile"></i>
        <i class="fa fa-search" style="color: white" *ngIf="!showMobile"></i> -->
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div> 
            <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
            <div class="overlay-content" style="margin-top: -200px;">
                <div class="container">
                  <img _ngcontent-serverapp-c33="" *ngIf="showMobile" alt="logo" style="height: 275px;"  src="https://apps.digitaldistrict.co.za/neo-proto-1/images/healthease_blue.png">
                  <img _ngcontent-serverapp-c33="" *ngIf="!showMobile" alt="logo" style="height: 275px;" class="offset-4" src="https://apps.digitaldistrict.co.za/neo-proto-1/images/healthease_blue.png">
                    <div class="row">
                        <div class="col-xl-12">
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search Products" [(ngModel)]="searchbar" [ngModelOptions]="{standalone: true}">
                                </div>
                                <!-- <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button> -->
                            </form>
                        </div>
                    </div>
                    <div class="results" *ngIf="(products | appFilter: searchbar)?.length > 0">
                      <div *ngFor="let product of products | appFilter: searchbar" [routerLink]="['/shop/product/right/sidebar/', product.title.replace(' ', '-')]"  (click)="searchToggle()" class="mt-1 mb-1">
                         {{product.title}} 
                      </div>
                    </div>
                    <div *ngIf="(products | appFilter: searchbar)?.length === 0">
                      <div class="col-sm-12 text-center">
                        <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                          <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
                          <p>Please check if you have misspelt something or try searching with other words.</p>
                          <a [routerLink]="['/shop/collection/right/sidebar']" (click)="searchToggle()" class="btn btn-solid">Explore Products</a>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </li>
    <li class="onhover-div">
      <div>
        <a [routerLink]="['/shop/cart']">
          <!-- <img src="assets/images/icon/cart.png" class="img-fluid" alt=""> -->
          <i class="fa fa-shopping-cart" style="color:black" *ngIf="showMobile"></i>
          <i class="fa fa-shopping-cart" style="color:white" *ngIf="!showMobile"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ cart.length }}</span>
      <!-- <ul class="show-div shopping-cart" *ngIf='!products.length'>
        <h5>Your cart is currently empty.</h5>
      </ul> -->
      <!-- <ul class="show-div shopping-cart" *ngIf='products.length'>
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="['/shop/product/right/sidebar/', product.title.replace(' ', '-')]">
              <img class="me-3" [src]="product?.images[0].src" [alt]="product?.images[0].alt">
            </a>
            <div class="media-body">
              <a [routerLink]="['/shop/product/right/sidebar/', product.title.replace(' ', '-')]">
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x 
                  {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
            <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
          </div>
        </li>
      </ul> -->
    </li>
    <li class="onhover-dropdown mobile-account">
      <i class="fa fa-user" style="font-size: 24px;" aria-hidden="true" [ngClass]="{'active': userLoggedIn}"></i>
      <ul class="onhover-show-div" style="position: fixed;">
        <li>
          <a *ngIf="!userLoggedIn" data-lng="en" [routerLink]="['/pages/login']">
            Login
          </a>
        </li>
        <li>
          <a *ngIf="userLoggedIn" data-lng="es" (click)="logout()">
            Logout
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>