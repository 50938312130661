import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-four',
  templateUrl: './header-four.component.html',
  styleUrls: ['./header-four.component.scss']
})
export class HeaderFourComponent implements OnInit {
  isMenuOpen = false;

  @Input() class: string = 'header-2 header-6';
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Input() dark: boolean;
  showMobile: boolean = true;
  screenWidth: any;
  public stick: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    console.log(this.dark)
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 769){
      this.showMobile = true;
    }
    else {
      this.showMobile = false;
    }  
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.screenWidth = (<Window>event.target).innerWidth;

    if (this.screenWidth < 769){
      this.showMobile = true;
    }
    else {
      this.showMobile = false;
    }  
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
    this.isMenuOpen = false; // Close the menu after navigation
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth  > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

}
