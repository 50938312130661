import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-neo-header',
  templateUrl: './neo-header.component.html',
  styleUrls: ['./neo-header.component.scss']
})
export class NeoHeaderComponent {
  public stick: boolean = false;
  isMenuOpen = false;
  showMobile: boolean = true;
  screenWidth: any;
  
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 769){
      this.showMobile = true;
    }
    else {
      this.showMobile = false;
    }  
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.screenWidth = (<Window>event.target).innerWidth;

    if (this.screenWidth < 769){
      this.showMobile = true;
    }
    else {
      this.showMobile = false;
    }  
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
    this.isMenuOpen = false; // Close the menu after navigation
  }

    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
      let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (number >= 150 && window.innerWidth  > 400) { 
        this.stick = true;
      } else {
        this.stick = false;
      }
    }
}
