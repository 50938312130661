import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-eezi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class EeziHeaderComponent  {

  constructor(private renderer: Renderer2){}

}
