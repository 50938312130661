import { Component, OnInit, Injectable, PLATFORM_ID, Inject, ViewEncapsulation, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None ,
})
export class SettingsComponent implements OnInit {

  public products: Product[] = [];
  public search: boolean = false;
  public userLoggedIn: boolean = false;
  searchbar: any = "";
  public cart: Product[] = [];
  
  showMobile: boolean = false;
  screenWidth: any;
  
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,
    private _firbaseService: FirebaseService) {
    this.productService.cartItems.subscribe(response => this.cart = response);
  }

  ngOnInit(): void {

    this._firbaseService.currentUser$.subscribe(data => {
      console.log(data);
      if(data !== null){
        this.userLoggedIn = true;
      } else {
        this.userLoggedIn = false;
      }
    });

    // this.searchbar.valueChanges.subscribe(data => {
    //   console.log(data);
    // });

    this.productService.getProducts.subscribe(data => {
      this.products = data;
    });

    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 769){
      this.showMobile = true;
    }
    else {
      this.showMobile = false;
    }  
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.screenWidth = (<Window>event.target).innerWidth;

    if (this.screenWidth < 769){
      this.showMobile = true;
    }
    else {
      this.showMobile = false;
    }  
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  logout(){
    this._firbaseService.logout();
  }



}
